<template>
    <el-dialog title="专题报告更新" :close-on-click-modal="false" :visible.sync="visible" width="540px">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" :inline="true" label-width="100px" style="margin-left: 20px;" class="border-form">
            <el-row class="set_border">
				<el-col :span="24">
                    <el-form-item label="标的物" prop="subjectNoList" class="item">
                       <el-select id="subjectNo" v-model="dataForm.subjectNoList" placeholder="请选择" style="margin-left:10px;width: 300px;" multiple clearable>
                            <el-option v-for="item in subjectList" :key="item.displayValue" :label="item.displayName"
                                :value="item.displayValue">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
			<el-row class="set_border">
				<el-col :span="24">
                    <el-form-item label="报告名称" prop="fileName" class="item">
                        <el-input v-model="dataForm.fileName" style="width:300px;margin-left: 10px;"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24">
                    <el-form-item label="报告时间" prop="createTime" class="item">
                        <el-date-picker style="width: 300px;margin-left: 10px;" v-model="dataForm.createTime" type="date"
                            value-format="yyyy-MM-dd" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24">
                    <el-form-item label="报告人" prop="createMan" class="item">
                        <el-input v-model="dataForm.createMan" style="width: 300px;margin-left: 10px;"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark" class="item">
                        <el-input v-model="dataForm.remark" style="width: 300px;margin-left: 10px;"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24">
					<el-form-item label="报告" class="item">
                        <el-upload v-if="!dataForm.filePath" ref="uploadScanFile" class="upload-demo" action="action"
                            drag :http-request="uploadScanFile" :show-file-list=false :auto-upload="true" style="margin-left: 10px;">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        </el-upload>
                        <div v-if="dataForm.filePath" style="margin-left: 10px;">
                            <el-button v-preventReClick type="success" size="small" 
                            @click="downloadFile(dataForm.filePathUrl, dataForm.fileName)">查看</el-button>
                            <el-button v-preventReClick type="primary" size="small" @click="delFile()">删除</el-button>
                        </div>
					</el-form-item>
				</el-col>
            </el-row>
            <el-row class="formBtnGroup" style="margin-top: 30px;">
                <el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
    data() {
        return {
            visible: false,
            dataForm: {
                workNo: $common.getItem("workNo"),
                fileList: []
            },
            subjectList: [],
            dataRule: {
                fileName: [
                    { required: true, message: "请填写报告名称", trigger: "blur" }
                ],
                createTime: [
                    { required: true, message: "请选择报告时间", trigger: "blur" }
                ],
                createMan: [
                    { required: true, message: "请填写报告人", trigger: "blur" }
                ],
                // subjectNoList: [
                //     { required: true, message: "请选择标的物", trigger: "blur" }
                // ],
            }
        };
    },
    components: {

    },
    activated() {

    },
    mounted() {
        this.getSubjectDropDown()
    },
    methods: {
        init(recId) {
            this.dataForm = {
                workNo: $common.getItem("workNo"),
                recId: recId,
                fileList: []
            };
            this.visible = true
            this.$nextTick(() => {
				this.$refs["dataForm"].resetFields();
				if (this.dataForm.recId) {
					this.$http({
						url: this.$store.state.httpUrl + `/business/projectworkfileinfo/getWorkFileInfoBySubjectNo/${this.dataForm.recId}`,
						method: 'get'
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.dataForm = data.body;
						}
					})
				}
			})
        },
        getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
				}
			});
		},
        uploadScanFile(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadScanFile.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/20/" + this.dataForm.fileKind,
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data;
				if (res.resultCode === 200) {
					this.dataForm.filePath = res.body.fileSavePath;
					this.dataForm.fileName = this.extractFileName(res.body.fileName);
					this.dataForm.filePathUrl = res.body.fileUri;
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
        extractFileName(fileName) {
            let index = fileName.lastIndexOf(".");
            return fileName.substr(0, index)
        },
        downloadFile(url, filename) {
            filename = this.getSuffix(url, filename)
            this.getBlob(url, (blob) => {
                this.saveAs(blob, filename)
            })
        },
        getSuffix(url, filename) {
            let lastIndex = url.lastIndexOf(".");
            return filename + url.substr(lastIndex)
        },
        getBlob(url, cb) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = function () {
                if (xhr.status === 200) {
                    cb(xhr.response)
                }
            }
            xhr.send()
        },
        saveAs(blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename)
            }
            else {
                var link = document.createElement('a')
                var body = document.querySelector('body')

                link.href = window.URL.createObjectURL(blob)
                link.download = filename

                link.style.display = 'none'
                body.appendChild(link)

                link.click()
                body.removeChild(link)
                window.URL.revokeObjectURL(link.href)
            }
        },
        delFile() {
            this.dataForm.filePath = "";
			this.dataForm.filePathUrl = "";
        },
        dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					const _url = "/business/projectworkfileinfo/updateWorkFileBySubjectNo";
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData({
							recId: this.dataForm.recId,
							workNo: $common.getItem("workNo"),
							subjectNoList: this.dataForm.subjectNoList,
							fileName: this.dataForm.fileName,
							fileKind: this.dataForm.fileKind,
							filePath: this.dataForm.filePath,
							createMan: this.dataForm.createMan,
							createTime: this.dataForm.createTime,
                            remark: this.dataForm.remark
						})
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$parent.getDataList();
								}
							});
						}
					});
				}
			});
		}
    }
};
</script>

<style scoped="scoped">
.upload-demo /deep/ .el-upload-dragger {
	width: 200px;
	height: 80px;
}

.upload-demo /deep/ .el-upload-dragger .el-icon-upload {
	font-size: 30px;
	margin: 8px 1px;
	line-height: 20px;
}

.upload-demo /deep/ .el-upload-dragger .el-upload__text {
	line-height: 5px;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}
</style>
